<script lang="ts" setup>
  const emits = defineEmits(['start-click'])
  const onClickRegister = () => {
    emits('start-click')
  }
  const isChecked = ref(true)
  const viewModal = ref(false)

  const agree = ref(false)

  const toggleStatus = () => {
    isChecked.value = !isChecked.value
  }
  const toggleModal = () => {
    viewModal.value = !viewModal.value
  }
</script>
<template>
  <div class="welcome">
    <div class="welcome_photo">
      <img src="~/assets/images/terms/login_image.jpg" alt="AIスマートコーチ" />
    </div>

    <div class="welcome_txt">
      <div class="text">
        <h2>
          <div class="title_img_wrapper">
            <img src="~/assets/images/logos/logo.svg" alt="AIスマートコーチ" />
          </div>

          <span>（PC版）</span>
        </h2>
        <p>
          各種スポーツのお手本動画の視聴と比較機能がご利用いただけます。比較機能は上手な選手と自分のフォームを比較分析することで、客観的にフォームの違いを知ることができ、効率よく練習できます。
        </p>
        <p>
          ※完全版はiPhoneアプリ、iPadアプリでご利用いただけます。目標設定と活動の記録や効果的な練習メニューが作成できる機能がご利用いただけます。<br />
          詳しくは<a
            href="https://smartcoach.mb.softbank.jp/lp/"
            target="_blank"
            >こちら</a
          >をご確認ください。
        </p>

        <div class="kiyaku_checkbox">
          <input type="checkbox" id="agree" v-model="agree" />
          <p>
            <button class="underline" type="button">
              利用規約
              <v-dialog v-model="viewModal" activator="parent">
                <AppTermsModal @close="toggleModal" />
              </v-dialog>
            </button>
            と<a href="https://www.softbank.jp/corp/privacy/" target="_blank"
              >プライバシーポリシー</a
            >と<a
              href="https://smartcoach.mb.softbank.jp/lp/site-policy.html"
              target="_blank"
              >サイトポリシー</a
            >に同意しました。
          </p>
        </div>
      </div>
      <div class="bottom">
        <button
          :class="{ active: agree }"
          @click="onClickRegister"
          type="submit"
          class="btn_start"
          :disabled="!agree"
        >
          はじめる
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .welcome {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 832px;
    border-radius: $radius_16;
    background: $monochrome-mono_01;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.4);
    min-height: 500px;
    max-height: calc(100vh - 96px);
    margin: auto 0;
    overflow: hidden;
    z-index: 1;

    .welcome_photo {
      width: 50%;
      height: 600px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        vertical-align: bottom;
        border-radius: $radius_16 0 0 $radius_16;
      }
    }

    .welcome_txt {
      width: 50%;
      min-height: 500px;
      max-height: calc(100vh - 96px);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 48px 24px 24px;
      justify-content: space-between;
      overflow: hidden;

      h2 {
        display: flex;
        align-items: flex-end;

        .title_img_wrapper {
          max-width: 240px;
          width: 70%;
          height: 48px;
          img {
            width: 100%;
            height: 48px;
            max-inline-size: 100%;
            max-block-size: 100%;
          }
        }

        span {
          color: $mono_07;
          font-size: 14px;
          display: inline-block;
          padding-bottom: 10px;
        }
      }

      p {
        color: $mono_05;
        font-size: 15px;
        padding-top: 16px;
      }

      p + p {
        color: $mono_03;
        font-size: 13px;
      }

      .kiyaku_checkbox {
        display: flex;
        flex-wrap: wrap;
        padding-top: 24px;

        input[type='checkbox'] {
          position: relative;
          width: 24px;
          height: 24px;
          border: 1px solid $mono_02;
          border-radius: $radius_4;
          cursor: pointer;
          -webkit-appearance: none;

          &:checked {
            background: $Primary_03;
            border: none;

            &::before {
              content: '';
              background: url('@/assets/images/terms/check_blk.png');
              width: 16px;
              height: 16px;
              position: absolute;
              top: 4px;
              left: 4px;
            }
          }
        }

        p {
          flex: 1;
          padding-left: 8px;
          padding-top: 0;
          padding-right: 1em;
          font-size: 15px;
          color: $mono_02;
        }
      }
    }

    .bottom {
      .btn_start {
        border-radius: $radius_4;
        background: $mono_07;
        color: $mono_01;
        opacity: 0.2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 368px;
        height: 48px;

        &.active {
          background: $Primary_03;
          opacity: 1;
          cursor: pointer;
          transition: all 0.5s ease 0s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    button.underline,
    a {
      color: $Primary_03;
      text-decoration: underline;
      font-size: 15px;
      transition: all 0.5s ease 0s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
  :deep(.v-overlay__content) {
    width: 480px;
  }
</style>
