<script lang="ts" setup>
  import IMAGE_GUIDE_TITLE from 'assets/images/guide/guide_title.svg'
  import IMAGE_GUIDE_MODAL from 'assets/images/guide/modal.svg'
  import { modalData } from '~/composables/guide'
  import { useMenuFolded, useMenuScrollTop } from '~/composables/common'
  import touchLottie from '~/assets/lottie/touch.json'

  const refDialog = ref()
  const dialogRect = ref({})
  const elementResizeObserver = ref()
  const props = defineProps({
    showModal: Boolean
  })
  watch(
    () => props.showModal,
    (newVal) => {
      if (newVal) {
        useMenuFolded().value = false
        useMenuScrollTop().value++
      }
    }
  )

  watch(refDialog, (value) => {
    if (value) {
      elementResizeObserver.value.observe(refDialog.value)
    }
  })
  const data = modalData

  onMounted(() => {
    elementResizeObserver.value = new ResizeObserver(() => {
      if (refDialog.value) {
        dialogRect.value.height = refDialog.value.getBoundingClientRect().height
      }
    })
  })

  onBeforeUnmount(() => {
    elementResizeObserver.value.disconnect(dialogRect.value)
  })
</script>

<template>
  <Transition name="modal">
    <div
      v-if="showModal"
      class="modal-mask"
      :style="{
        '--highlight-t': `${data.elementY}px`,
        '--highlight-r': `${
          data.windowWidth - data.elementX - data.elementWidth
        }px`,
        '--highlight-b': `${
          data.windowHeight - data.elementY - data.elementHeight
        }px`,
        '--highlight-l': `${data.elementX}px`,
        '--highlight-w': `${data.elementWidth}px`,
        '--highlight-h': `${data.elementHeight}px`,
        '--modal-x': `${data.modalX}px`,
        '--modal-y': `${data.modalY}px`,
        '--dialog-h': `${dialogRect.height}px`
      }"
    >
      <div class="image-title">
        <img :src="IMAGE_GUIDE_TITLE" alt="" />
      </div>
      <template v-if="data.step == 3">
        <img :src="IMAGE_GUIDE_MODAL" class="image-modal" alt="" />
        <div class="shadow-mask-full" />
      </template>
      <div v-if="data.step != 3" class="shadow-mask" />
      <div ref="refDialog" class="modal-container">
        <div v-if="data.modalArrow == 'top'" class="arrow-top" />
        <div v-if="data.modalArrow == 'left'" class="arrow-left" />
        <client-only>
          <Vue3Lottie
            :class="['icon-touch', `icon-touch-${data.touchIconPosition}`]"
            :animation-data="touchLottie"
            width="48px"
            height="48px"
          />
        </client-only>
        <div class="modal-main">
          <slot name="header"></slot>
          <slot name="body"></slot>
          <v-divider />
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
  .ban-pointer {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .modal-mask {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .shadow-mask {
    position: fixed;
    z-index: 10000;
    top: var(--highlight-t);
    left: var(--highlight-l);
    width: var(--highlight-w);
    height: var(--highlight-h);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 max(100vw, 100vh);
    transition: opacity 0.3s ease;
  }

  .shadow-mask-full {
    position: fixed;
    z-index: 10000;
    top: var(--highlight-t);
    left: var(--highlight-l);
    width: var(--highlight-w);
    height: var(--highlight-h);
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 max(100vw, 100vh);
    transition: opacity 0.3s ease;
  }

  .image-title {
    @extend .ban-pointer;
    position: fixed;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 10001;
  }

  .image-modal {
    @extend .ban-pointer;
    position: fixed;
    left: calc(var(--highlight-l) - 10px);
    top: calc(var(--highlight-t) - 263px);
    width: 375px;
    height: 263px;
    z-index: 10001;
  }

  .modal-container {
    box-sizing: border-box;
    position: absolute;
    z-index: 10001;
    left: var(--modal-x);
    top: calc(min(var(--modal-y), 100vh - var(--dialog-h)));
    width: 392px;
    padding-top: 16px;
    background-color: #fff;
    border-radius: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 8px 24px 0 #ffa70066;
    border: 2px solid #ffa700;

    .arrow-top {
      position: absolute;
      top: -12px;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      border-radius: 0 10px 0 0;
      transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
      background: white;
      border-top: 2px solid #ffa700;
      border-right: 2px solid #ffa700;
    }

    .arrow-left {
      position: absolute;
      top: calc(50% - 10px);
      left: -12px;
      width: 20px;
      height: 20px;
      border-radius: 0 10px 0 0;
      transform: rotate(-150deg) skewX(-30deg) scale(1, 0.866);
      background: white;
      border-top: 2px solid #ffa700;
      border-right: 2px solid #ffa700;
    }

    .icon-touch {
      position: absolute;
      z-index: 1;
      pointer-events: none;

      &-left {
        top: calc(50% - 10px);
        left: -80px;
      }

      &-right-bottom {
        right: 0;
        bottom: 0;
      }
    }

    .modal-main {
      position: relative;
    }
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter-from {
    opacity: 0;
  }

  .modal-leave-to {
    opacity: 0;
  }

  .modal-enter-from .modal-container,
  .modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
