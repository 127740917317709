<script lang="ts" setup>
  const updateIsFirst = () => {
    useRegistered()
    const router = useRouter()
    router.push('/')
  }
</script>

<template>
  <AppPagesRegister v-if="useRegister().value" @register="updateIsFirst" />
  <div class="page" v-else>
    <AppMenu />
    <AppGuide />
    <div class="content">
      <slot />
      <AppApiDialog v-if="useApiDialogShow().value" />
      <AppLoading v-if="useShowGlobalLoading().value" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

  * {
    font-family: 'Hiragino Sans', 'ヒラギノ角ゴシック', '游ゴシック',
      'Yu Gothic', 'メイリオ', 'Meiryo', 'Noto Sans JP', sans-serif !important;
  }

  .page {
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;

    .content {
      position: relative;
      min-width: 696px;
      flex-shrink: 0;
      flex: 1;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
</style>
