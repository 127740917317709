<script lang="ts" setup>
  const emits = defineEmits(['register'])
  const handleStartClick = async () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('auth_web', 'true')
      await deleteDB('common', 'stopOrganizationHistory')
      await getToken()
      emits('register')
    }
  }
</script>
<template>
  <div class="kiyaku_contents">
    <h1>
      <img src="@/assets/images/logos/logo.svg" alt="AIスマートコーチ" />
    </h1>
    <AppTermsDialog @start-click="handleStartClick" />
  </div>
</template>

<style lang="scss" scoped>
  .full {
    width: 100vw;
    height: 100vh;
  }
  .kiyaku_contents {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: url('@/assets/images/terms/login_bg_light.png') no-repeat center
      center/cover;
    position: relative;

    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h1 {
      position: fixed;
      width: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      img {
        width: 160px;
        height: auto;
        max-inline-size: 100%;
        max-block-size: 100%;
      }
    }
  }
</style>
