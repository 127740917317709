<script lang="ts" setup>
  const confirm = () => {
    // confirm callback
    useApiDialogCallback().value()
    //  reset api dialog
    useApiDialogShow().value = false
    useApiDialogTitle().value = ''
    useApiDialogMessage().value = ''
    useApiDialogCallback().value = () => {}
  }
</script>

<template>
  <AppDialogCommon
    :is-show="true"
    :title="useApiDialogTitle().value"
    :message="useApiDialogMessage().value"
  >
    <template #actions>
      <button class="dialog-btn dialog-confirm" @click="confirm">OK</button>
    </template>
  </AppDialogCommon>
</template>

<style scoped></style>
