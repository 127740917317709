<script setup>
  import { useMenuFolded, useMenuScrollTop } from '~/composables/common'

  const isFolded = useMenuFolded()
  const showMore = ref(false)
  const copyToolip = ref(false)
  const moreContainerPositon = reactive({
    left: '',
    top: ''
  })
  const tabbars = reactive({
    list: [
      [
        {
          text: 'ホーム',
          icon: 'home',
          url: '/'
        },
        {
          text: 'マイリスト',
          icon: 'my_list',
          url: '/my_list'
        },
        {
          text: '比べる',
          icon: 'compare',
          url: '/compare'
        }
      ],
      [
        {
          text: '団体利用',
          icon: 'group',
          url: '/organizations'
        },
        {
          text: '履歴',
          icon: 'history',
          url: '/history'
        },
        {
          text: '画質設定',
          icon: 'image_quality',
          url: '/video_resolution'
        },
        {
          text: '指導監修・選手紹介',
          icon: 'analyse',
          url: '/performers'
        }
      ],
      [
        {
          text: '使い方ガイド',
          icon: 'guide',
          url: '/use'
        },
        {
          text: 'お知らせ一覧',
          icon: 'notification',
          url: '/info'
        },
        {
          text: 'よくあるご質問',
          icon: 'qa',
          url: '/faq'
        }
      ]
    ],
    info: [
      {
        text: 'スマートコーチ',
        url: '/sc'
      },
      {
        text: '利用規約',
        url: '/terms'
      },
      {
        text: 'プライバシーポリシー',
        url: '/privacy'
      },
      {
        text: 'サイトポリシー',
        url: 'サイトポリシー'
      },
      {
        text: 'コピーライト',
        url: '/copyright'
      }
    ]
  })

  const icon = (icon) => {
    return new URL(`/assets/images/menu/${icon}.png`, import.meta.url).href
  }

  const clickMore = (event) => {
    showMore.value = true
    const rect = event.currentTarget.getBoundingClientRect()
    moreContainerPositon.left = rect.right + 'px'
    moreContainerPositon.top = rect.bottom + 'px'
  }

  const clickFold = () => {
    isFolded.value = !isFolded.value
    if (isFolded.value) {
      useMenuWidth().value = '87px'
    } else {
      useMenuWidth().value = '320px'
    }
  }

  const windowResize = () => {
    showMore.value = false
  }

  const goto = async (url) => {
    if (url === 'サイトポリシー') {
      window.open('https://smartcoach.mb.softbank.jp/lp/site-policy.html', '_blank')
    } else {
      navigateTo(url)
    }
  }

  const menuRef = ref()
  const menuScrollTop = useMenuScrollTop()

  watch(menuScrollTop, () => {
    scrollToTheTop()
  })
  const scrollToTheTop = () => {
    nextTick(() => {
      const menu = menuRef.value
      menu.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  onMounted(() => {
    window.addEventListener('resize', windowResize)
  })
</script>

<template>
  <div class="menu-container">
    <transition name="expand">
      <div ref="menuRef" class="menu" :class="isFolded ? 'folded' : ''">
        <div class="logo"></div>
        <div class="list">
          <div v-for="(list, index) in tabbars.list" :key="index">
            <div
              v-for="item in list"
              class="tabbar"
              :class="item.url == useMenuSelected().value ? 'selected' : ''"
              @click="goto(item.url)"
            >
              <client-only>
                <img class="icon" :src="icon(item.icon)" alt="" />
              </client-only>
              <p class="text">{{ item.text }}</p>
              <div v-if="!isFolded">
                <img
                  src="@/assets/images/menu/right_arrow_selected.png"
                  v-if="item.url == useMenuSelected().value"
                  class="arrow"
                  alt=""
                />
                <img
                  src="@/assets/images/menu/right_arrow.png"
                  v-else
                  class="arrow"
                  alt=""
                />
              </div>
            </div>
            <div class="separator" v-if="index !== tabbars.list.length - 1">
              <div></div>
            </div>
          </div>
        </div>
        <div class="info" v-if="!isFolded">
          <div class="separator">
            <div></div>
          </div>
          <div
            class="item"
            v-for="item in tabbars.info"
            @click="goto(item.url)"
          >
            <p>{{ item.text }}</p>
          </div>
          <div class="separator">
            <div></div>
          </div>
        </div>
        <div class="copyright" v-if="!isFolded">
          <p>© SoftBank Corp. All Rights Reserved.</p>
        </div>

        <div class="more" ref="more" v-else @click="clickMore"></div>
      </div>
    </transition>
    <transition name="expand">
      <div
        class="fold"
        :class="isFolded ? 'folded' : ''"
        @click="clickFold"
      ></div>
    </transition>
  </div>
  <div class="mask" v-if="isFolded && showMore" @click="showMore = false">
    <div class="more-container" :style="moreContainerPositon">
      <div
        class="item"
        :class="index === tabbars.info.length - 1 ? 'last' : ''"
        v-for="(item, index) in tabbars.info"
        :key="index"
        @click="goto(item.url)"
      >
        <p>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .menu-container {
    position: relative;
  }

  .menu {
    position: relative;
    width: 320px;
    height: 100vh;
    overflow-y: auto;
    background: #ffffff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    transition: width 0.2s;
    flex-shrink: 0;
    z-index: 9999;

    &:hover + .fold {
      display: block;
    }

    .logo {
      margin-left: 32px;
      margin-top: 24px;
      width: 160px;
      height: 32px;
      background-image: url(~/assets/images/menu/logo.png);
      background-size: 100% 100%;
    }

    .list,
    .info {
      .separator {
        width: 288px;
        height: 32px;
        padding: 16px;

        & > div {
          width: 256px;
          height: 1px;
          border-top: 1px solid rgb(233, 233, 234);
        }
      }
    }

    .list {
      margin: 16px 16px 0;

      .tabbar {
        width: 288px;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 20px 16px;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          background-color: rgb(239, 247, 246);

          .arrow {
            display: block;
          }
        }

        &:hover {
          background-color: rgb(233, 233, 234);

          .arrow {
            display: block;
          }
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }

        .text {
          font-weight: 600;
          font-size: 16px;
          color: rgb(32, 37, 41);
          text-align: left;
          flex-grow: 1;
        }

        .arrow {
          width: 16px;
          height: 16px;
          display: none;
        }
      }
    }

    .info {
      margin: 0 16px;

      .item {
        width: 288px;
        height: 53px;
        padding: 16px;
        cursor: pointer;

        &:hover {
          p {
            color: rgb(0, 204, 192);
          }
        }

        p {
          vertical-align: middle;
          font-weight: 600;
          font-size: 14px;
          color: rgb(143, 146, 148);
        }
      }
    }

    .copyright {
      width: 288px;
      height: 50px;
      margin: 0 16px 16px;

      p {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: rgb(188, 190, 191);
      }
    }

    &.folded {
      width: 88px;
      transition: width 0.2s;

      .logo {
        margin: 16px auto 0;
        width: 32px;
        height: 32px;
        background-image: url(~/assets/images/menu/logo_small.png);
      }

      .list {
        margin: 12px 12px 0;

        .tabbar {
          width: 64px;
          height: auto;
          padding: 10px 4px;
          flex-direction: column;
          align-items: center;
          transition: all 0.2s;

          .icon {
            margin-right: 0;
          }

          .text {
            font-weight: 300;
            font-size: 11px;
            line-height: 15px;
            text-align: center;
          }
        }

        .separator {
          width: 64px;
          height: 32px;
          padding: 16px 0;

          & > div {
            width: 100%;
            height: 1px;
            border-top: 1px solid rgb(233, 233, 234);
          }
        }
      }

      .more {
        width: 64px;
        height: 44px;
        margin: 0 12px;
        cursor: pointer;
        background: url(~/assets/images/menu/more.png) no-repeat center center;
        background-size: 16px 16px;

        &:hover {
          background-color: rgb(233, 233, 234);
          border-radius: 4px;
        }
      }
    }
  }

  .menu::-webkit-scrollbar {
    display: none;
  }

  .fold {
    position: absolute;
    float: right;
    display: none;
    width: 24px;
    height: 80px;
    top: 50%;
    margin-top: -40px;
    left: 319px;
    background-image: url(~/assets/images/menu/fold.png);
    background-size: 100% 100%;
    transition: left 0.2s;
    z-index: 9999;

    &:hover {
      display: block;
      background-image: url(~/assets/images/menu/fold_hover.png);
    }

    &.folded {
      left: 87px;
      transition: left 0.2s;
      background-image: url(~/assets/images/menu/folded.png);

      &:hover {
        background-image: url(~/assets/images/menu/folded_hover.png);
      }
    }
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    // background-color: transparent;
    .more-container {
      position: absolute;
      width: 178px;
      height: 207px;
      margin-top: -207px;
      border-radius: 8px;
      padding: 8px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);

      .item {
        width: 176px;
        height: 37px;
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
          background-color: rgb(233, 233, 234);
        }

        p {
          vertical-align: middle;
          font-weight: 300;
          font-size: 14px;
          color: rgb(32, 37, 41);
        }
      }
    }
  }
</style>

