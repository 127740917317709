<script setup lang="ts">
  import Modal from '~/components/app/guide/modal.vue'
  import {
    isShowGuideModal,
    currentStepData,
    nextTutorialInstructions,
    terminateTutorialInstructions
  } from '~/composables/guide'

  const showModal = isShowGuideModal
  const data = currentStepData
</script>
<template>
  <Teleport to="body">
    <Modal :show-modal="showModal" @close-guide="showModal = false">
      <template #header>
        <div class="modal-header">
          {{ data.title }}
        </div>
      </template>
      <template #body>
        <div v-for="(item, index) in data.body" :key="index" class="modal-body">
          <div class="modal-body-title">
            <div class="modal-body-title-point"></div>
            {{ item.title }}
          </div>
          <div class="modal-body-value">
            {{ item.value }}
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-footer">
          <div class="step-area">
            <div class="current-step">{{ data.step }}</div>
            /3
          </div>
          <div class="btn-area">
            <div
              v-if="data.step != 3"
              class="btn-cancel"
              @click="terminateTutorialInstructions"
            >
              スキップ
            </div>
            <div
              v-if="data.step != 3"
              class="btn-next"
              @click="nextTutorialInstructions"
            >
              次へ
            </div>
            <div v-else class="btn-next" @click="nextTutorialInstructions">
              完了
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<style scoped lang="scss">
  .ban-pointer {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  .modal-header {
    @extend .ban-pointer;
    margin-top: 0;
    font-size: 20px;
    padding: 0 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
    color: #000000;
    margin-bottom: 16px;
  }

  .modal-body {
    @extend .ban-pointer;
    margin: 20px 0;
    padding: 0 16px;
    letter-spacing: 0;

    &-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: #000000;

      &-point {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #000;
        margin-bottom: 3px;
      }
    }

    &-value {
      margin-left: 1ch;
      font-size: 16px;
      font-weight: 300;
      line-height: 32px;
      color: #000000;
    }
  }

  .modal-footer {
    @extend .ban-pointer;
    display: flex;
    padding: 16px;

    .step-area {
      display: flex;
      align-items: center;

      .current-step {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: #ffa700;
      }

      color: #8f9294;
    }

    .btn-area {
      display: flex;
      align-items: center;
      margin-left: auto;

      .btn-cancel {
        pointer-events: auto;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        margin-right: 24px;
        color: #8f9294;
      }

      .btn-next {
        pointer-events: auto;
        cursor: pointer;
        width: 122px;
        height: 40px;
        border-radius: 99px;
        font-size: 14px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0;
        text-align: center;
        color: #fff;
        background: #ffa700;
      }
    }
  }
</style>
